import React, { useState } from 'react';
import classes from 'classnames';
import RcDropdown from 'rc-dropdown';
import RcMenu from 'rc-menu';
import { MenuInfo } from 'rc-menu/lib/interface';
import { MenuProps as RcMenuProps } from 'rc-menu/lib/Menu';
import { useIntl } from 'react-intl';

interface MenuProps extends RcMenuProps {
  onItemClick: (value: string, domEvent?: React.MouseEvent) => void;
}

const Menu = ({ className, children, onItemClick, ...props }: MenuProps) => {
  const handleClick = ({ key, domEvent }: MenuInfo) => {
    onItemClick(key as string, domEvent as React.MouseEvent);
  };

  return (
    <RcMenu
      className={classes('focus:outline-none', className)}
      {...props}
      onClick={handleClick}
    >
      {children}
    </RcMenu>
  );
};

type Placement = 'bottomRight';

interface Props {
  onSelect?: (value: string, e?: React.MouseEvent) => void;
  isEventSelect?: boolean;
  menuItems: React.ReactNode[];
  children: React.ReactNode;
  className?: string;
  placement?: Placement;
  noOptionsMessage?: string;
  onMenuClick?: () => void;
  onVisibleChange?: (visible: boolean) => void;
  menuClassname?: string;
  trigger?: string[];
}

const Dropdown = ({
  onSelect,
  className,
  children,
  menuItems,
  placement,
  noOptionsMessage,
  onMenuClick,
  onVisibleChange,
  menuClassname,
  trigger,
  isEventSelect = false,
}: Props) => {
  const { formatMessage: f } = useIntl();
  const [visible, setVisible] = useState(false);

  const menu = (
    <Menu
      className={menuClassname}
      onItemClick={(value, e) => {
        onSelect && isEventSelect && e && onSelect(value, e);
        onSelect && !isEventSelect && onSelect(value);
        setVisible(false);
        onVisibleChange?.(false);
      }}
    >
      {menuItems.length > 0 ? (
        menuItems
      ) : (
        <div className="px-16 py-12 cursor-default text-grey-plus1">
          {noOptionsMessage ||
            f({
              id: 'common.select.noOptionsMessage',
            })}
        </div>
      )}
    </Menu>
  );

  return (
    <RcDropdown
      overlay={menu}
      overlayClassName={classes(
        'DropdownMenu rounded shadow-md py-8 bg-white',
        className,
        {
          hidden: !visible,
        },
      )}
      onVisibleChange={(isVisible: boolean) => {
        onMenuClick?.();
        setVisible(isVisible);
        onVisibleChange?.(isVisible);
      }}
      visible={visible}
      trigger={trigger || ['click']}
      {...(placement ? { placement } : {})}
    >
      {children}
    </RcDropdown>
  );
};

export default Dropdown;
